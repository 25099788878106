/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, StateCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Ménière’s disease is primarily a unilaterally occurring disease of the cochleovestibular organ in the inner ear."), " It is characterized by a sudden onset of vertigo and vomiting, ringing in the ears, and temporary ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), ". The symptoms can appear from one minute to the next and improve or disappear after a few hours. These “paroxysmal attack” symptoms occur very infrequently, but stressful situations can act as a trigger for the attack."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "emergence",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#emergence",
    "aria-label": "emergence permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Emergence"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ménière’s disease is a disorder of the inner ear (vestibular system in the inner ear)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "About 5% to 10% of ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "patients with vertigo"), " are diagnosed with Ménière’s disease. ", React.createElement(_components.strong, null, "It is most common in patients between the ages of 30 and 50, and women are affected slightly more often than men.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The exact cause of Ménière’s disease has not been identified. However, it is speculated that heredity or previous traumatic inner ear damage plays a significant role."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ménière’s disease is also referred to ", React.createElement(_components.strong, null, "as endolymphatic hydrops."), " To understand this disease, you need to familiarize yourself with the structure of the inner ear which consists of two parts. The first is the vestibular system and the second is the cochlea. Below you will learn about the structure of the cochlea, since it is closely associated with Ménière’s disease. The cochlea contains a bony labyrinth in which the inner membranous labyrinth is embedded."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The membranous labyrinth, from top to bottom, is composed of three “chambers”: the scala vestibuli, the scala media, and the scala tympani. Scala vestibuli and scala media are referred to as Reissner’s membrane, and the scala tympani and scala media are called the basilar membrane. The basilar membrane is part of the organ of Corti and is the base for different sized hair cells (sensory cells), which are responsible for the actual hearing process. Scala tympani and scala vestibuli are filled with a fluid called perilymph. The scala media is filled with a potassium-rich fluid called endolymph."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Regarding endolymphatic hydrops, an overproduction of endolymph results in a widening of the scala media and a shift of the basilar membrane. Consequently, this has a negative effect on the hair cells that sit on the basilar membrane, leading to hearing loss and ringing in the ears (tinnitus). The endolymphatic hydrops and the widening of the scala media can also be a rupture of the Reissner’s membrane. In this case, there is a mixing of the endolymph of the scala media and the perilymph of the scala vestibuli. This can then cause a person to experience vertigo."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms--diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms--diagnosis",
    "aria-label": "symptoms  diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms & Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The three typical symptoms of Ménière’s disease are hearing loss (in varying degrees), tinnitus, and dizziness lasting about three hours. Doctors refer to this as a triad of symptoms. Before and during the “attack,” the degree of hearing loss and tinnitus may change. In most cases, hearing loss worsens and the symptoms of tinnitus get louder. Problems with balance usually remain longer after an attack, but they eventually recede."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If the symptoms of Ménière’s disease are present, a diagnosis will be easier to make."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1."), " During an attack, a patient may experience eye twitches in the direction of the normal ear. Doctors refer to this irregular eye movement as Nystagmus. Soon after, the twitching reverses its direction to the blocked or affected ear (Erholungsnystagmus)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2."), " The hearing loss affects mainly tones in the low and mid-range frequencies, which can be easily diagnosed with an audiogram."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "3."), " If the disease is more advanced, there is a hyposensitivity in the organ of balance (vestibular)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "therapy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#therapy",
    "aria-label": "therapy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Therapy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are ", React.createElement(_components.strong, null, "two different therapeutic approaches"), " for Ménière’s disease. First, treatment can take place ", React.createElement(_components.strong, null, "during an attack."), " Second, different therapeutic measures can be used to ", React.createElement(_components.strong, null, "prevent the next attack"), " or at least to reduce the number of occurrences. Depending on the severity of Ménière’s disease, a doctor will determine which treatment is needed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Below you will find the different treatment options for Ménière’s disease. These include the attack reduction therapy, the interval therapy, and surgery. Below you will also find more information about preventing Ménière’s disease."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Attack reduction therapy")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "This option only treats the symptoms,"), " not the cause itself. Doctors refer to this as a symptomatic treatment. Typical symptoms of Ménière’s disease include tinnitus, hearing loss, and vertigo. In an acute attack situation, tinnitus can be treated with simple diet changes and prescription drugs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The dizziness can be treated with antivertiginous (stops dizziness) and antiemetic (stops vomiting) drugs. The preferred drug is dimenhydrinate, which inhibits the H1 receptors in the vomiting center of the central nervous system, thus relieving nausea and dizziness. Additionally, bed rest is highly recommended. If an attack includes frequent vomiting, plenty of fluids should be drank to compensate for the loss of liquids and electrolytes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Prevention of further attacks")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To further prevent Ménière’s disease attacks, one should avoid as much as possible psychologically stressful situations. Moreover, making sure your blood pressure is normal with the help of doctor is crucial to avoid a drop in blood pressure (hypotension). Treatment of the cervical spine may also be helpful."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Interval therapy")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Interval therapy is a treatment that takes place between attacks. Betahistine is typically used for this kind of therapy, and it is also effective against dizziness. It is still uncertain whether this drug has a positive effect on people with Ménière’s disease. However, doctors still prescribe it regularly as a form of treatment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Surgical treatment measures")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If Ménière’s disease cannot be treated with a form of drug therapy, the only remaining option is surgery. There are two types of surgery:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1."), " The first is the severing of the respective balance nerve (vestibular nerve). Doctors speak here of a vestibular neurotomy."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2."), " The second is the removal of the labyrinth, commonly referred to as a labyrinthectomy. In this method, the vestibule and the hearing organ are removed. This surgery only applies to patients with Meniere’s disease who have already lost their hearing and are deaf."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A less invasive method is intratympanic gentamicin instillation, which is the “elimination” of the labyrinth. Gentamicin is toxic to the inner ear and the labyrinth, destroying them upon contact. Here, the gentamicin goes through the tympanic membrane to the middle ear (intratympanically) and into the inner ear, where the labyrinth is located."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Determining with certainty how long Ménière’s disease will last is not easy. In most cases, the disease is chronic. Attacks can come and go for a few days, months or even years. Dizziness may be more infrequent, and the hearing loss produced by Ménière’s disease may lead to deafness."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ménières-disease-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#m%C3%A9ni%C3%A8res-disease-and-hearing-aids",
    "aria-label": "ménières disease and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ménière's disease and hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the consequences associated with Ménière’s disease is not only deafness but also social isolation. ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "Hearing aids are designed to help prevent both"), ". While a person’s hearing ability may fluctuate over the course of the disease, regular adjustments to the settings of the hearing aids will help normalize this situation. Therefore, patients are advised to start wearing hearing aids as soon as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ear-nose-and-throat-doctor-medical-advice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ear-nose-and-throat-doctor-medical-advice",
    "aria-label": "ear nose and throat doctor medical advice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ear, nose and throat Doctor medical advice"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is no question about it — Ménière’s disease is not something to ignore. It has major health implications. An ear, nose and throat doctor (ENT) should be consulted (ENT) if you suspect your symptoms to be signs of Ménière’s disease."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Has your doctor concluded that you are a candidate for hearing aids? Then let us help you find the right hearing aids. Our services are free and without obligation. Call today!"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
